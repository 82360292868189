.text-only {
  margin-bottom: 3.75rem;

  p + h2,
  p + h3,
  p + h4,
  p + h5,
  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5 {
    padding-top: 2rem;
  }

  li {
    margin-bottom: .25rem;
  }
}