.nav-mobile {
  background: var(--color-nav-background);
  height: calc(100vh - var(--header-outer-height) + 1rem);
  left: 100%;
  overflow-y: auto;
  padding: 1.5rem;
  position: fixed;
  top: var(--header-inner-height);
  transition: left 0.5s ease-in-out;
  width: 100%;
  z-index: 2;

  @media(min-width: $lg) {
    display: none;
  }

  &.open {
    left: 0;
  }

  &__item {
    border-bottom: solid 1px var(--color-base);
    padding: .625rem 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__link {
    color: var(--color-black-100);
    display: flex;
    font-weight: 300;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;

    &::after {
      content: url(/dist/img/icon-dropdown-arrow.svg);
    }

    &.active {
      &::after {
        transform: scaleY(-1);
      }

      & + ul {
        display: block;
      }
    }

    & + ul {
      display: none;
    }

   &--no-children {
     &::after {
       content: none;
     }
   }
  }

  &--secondary,
  &--tertiary {
    padding: .875rem 0;

    @media (min-width: $md) {
      display: none;
    }

    .nav-mobile__link {
      font-size: .875rem;
    }
  }

  &--secondary {
    .nav-mobile__item {
      background-color: var(--color-base);
      margin-bottom: .625rem;
      padding: .75rem 1rem .5rem;

      &:last-of-type,
      &--no-children {
        margin-bottom: 0;
      }

      &--no-children {
        padding: .5rem 1rem;

        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }
    }

    .nav-mobile__link {
      font-weight: 700;
    }
  }

  &--tertiary {
    .nav-mobile__item {
      padding: 0;
    }

    .nav-mobile__link {
      font-weight: 300;

      &::after {
        content: none;
      }
    }
  }
}
