.paging {
  &__page {
    align-items: center;
    color: var(--color-black-100);
    display: flex;
    flex-direction: column;
    height: 2.5rem;
    justify-content: center;
    line-height: 1;
    text-decoration: none;
    width: 2.5rem;

    &:hover,
    &.current {
      background: var(--color-primary-100);
      border-radius: 2.5rem;
      color: var(--color-base);

      rect {
        fill: var(--color-primary-100);
      }

      path {
        fill: var(--color-base);
      }
    }
  }
}