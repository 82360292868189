.forms-search {
  display: flex;

  &__input {
    appearance: none;
    border: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding: 0.5rem;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__button {
    appearance: none;
    background-color: var(--color-secondary-100);
    border: 0;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    color: var(--color-base);
    padding: 0.5rem 1rem;

    @media(min-width: $md) {
      padding: 0.5rem 1.75rem;
    }
  }
}