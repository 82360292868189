.heading {
  margin-bottom: 1rem;

  &--left h2 {
    text-align: left;
  }
  &--right h2 {
    text-align: right;
  }
  &--middle h2 {
    text-align: center;
  }
}