.product-carousel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  @media(min-width: $md) {
    flex-direction: row;
    margin-bottom: 0;
  }

  &__img,
  .splide__slide {
    border-radius: 8px;
  }

  &__img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  &__main {
    max-width: 654px;
  }

  &__nav {
    .splide__slide {
      opacity: 0.5;

      &.is-active {
        opacity: 1;
      }
    }

  }

  .splide__slide {
    border: 0 !important;
  }
}