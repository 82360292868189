// HEADINGS
@mixin font-preset--1 {
	font-family: var(--font-two);
	font-size: 2.25rem;
	font-weight: 700;

	@media(min-width: $xs) {
		font-size: 2.25rem;
	}

	@media(min-width: $lg) {
		font-size: 4.5rem;
	}
}

@mixin font-preset--2 {
	font-family: var(--font-two);
	font-size: 2rem;
	font-weight: 700;
}

@mixin font-preset--3 {
	font-family: var(--font-one);
	font-size: 1.25rem;
	font-weight: 700;

	@media(min-width: $lg) {
		font-size: 1.5rem;
	}
}

@mixin font-preset--3e {
	@include font-preset--3;
	font-family: var(--font-two);
}

@mixin font-preset--4 {
	font-family: var(--font-one);
	font-size: 1.25rem;
	font-weight: 700;
}

// BODY COPY
@mixin font-preset--5 {
	font-family: var(--font-one);
	font-size: 1rem;
	font-weight: 400;
}

@mixin font-preset--6 {
	font-family: var(--font-one);
	font-size: .75rem;
	font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
	margin: 0;
	line-height: 1.2;
}

a:not([class]) {
	color: currentColor;
}

ul,
ol {
	list-style: disc;
	padding-left: 1.8ex;
}

li {
	margin: 0;
}

.heading--1,
h1,
.heading--2,
h2,
.heading--3,
h3 {
	color: var(--color-primary-100);
}

.heading--1,
h1 {
	@include font-preset--1;
}

.heading--2,
h2 {
	@include font-preset--2;
	margin-bottom: .75rem;
}

.heading--3,
h3 {
	@include font-preset--3;
}

.heading--3e {
	@include font-preset--3e;
}

.heading--4,
h4 {
	@include font-preset--4;
}

p {
	@include font-preset--5;
}

.small,
.small p {
	@include font-preset--6;
}


