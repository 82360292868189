.order-form {
  max-width: 300px;

  &__heading {
    color: var(--color-black-100);
    font-family: var(--font-one);
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__img {
    margin-bottom: 1rem;
  }

  &__tags {
    padding: .75rem 0;
  }

  &__button {
    background: var(--color-brand-custom);
    border-radius: 8px;
    color: var(--color-base);
    display: block;
    margin-bottom: .75rem;
    padding: 8px 8px 6px 12px;
    position: relative;
    text-decoration: none;
    width: 100%;

    &::after {
      content: url("/dist/img/icon-arrow-angled.svg");
      height: 20px;
      width: 20px;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--download {
      background: var(--color-primary-100);

      &::after {
        content: url("/dist/img/icon-download.svg");
      }
    }
  }
}