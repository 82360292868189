.dropdown {
    background-color: var(--color-base);
    display: block;
    margin: 0 0 2rem;
    padding: 8px 12px;
    gap: 4px;
    border-radius: 8px;
    border: 2px;

    &--tight {
        margin: 0 0 1rem;
    }

    &__label {
        display: block;
        font-size: .75rem;
        font-weight: 300;
    }

    &__wrapper {
        display: flex;
        gap: .5rem;
    }

    &__field {
        display: flex;
        appearance: none;
        background: transparent url(/dist/img/icon-dropdown-arrow.svg) no-repeat 100% 40%;
        background-size: 12px 7px;
        border: 0;
        min-width: 180px;
        padding-left: 0;
        padding-right: 1rem;
        position: relative;
        width: 100%;
    }
}