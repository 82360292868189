.widget-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  flex-grow: 1;
  margin-bottom: 2rem;
  padding: 2rem;

  &__heading,
  &__description p {
    color: var(--color-base);
    font-weight: 700;
  }

  &__heading {
    font-family: var(--font-two);
    font-size: 2rem;
    margin-bottom: .75rem;
    text-shadow: 0 4px 8px rgba(38, 38, 38, 0.20);
  }

  &__description {
    margin-bottom: 1.5rem;
  }
}