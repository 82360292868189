$xs: 375px;
$sm: 576px;
$st: 768px; // Small Tablet (in case you were wondering!)
$md: 810px;
$lg: 1074px;
$xl: 1200px;
$xxl: 1400px;

:root {
	// COLOURS
	--color-primary-100: #3C58A2;
	--color-primary-80: #6379B5;
	--color-primary-60: #8A9BC7;
	--color-primary-40: #B1BCDA;
	--color-primary-20: #D8DEEC;
	--color-secondary-100: #00945E;
	--color-secondary-80: #33A97E;
	--color-secondary-60: #66BF9E;
	--color-secondary-40: #99D4BF;
	--color-secondary-20: #CCEADF;
	--color-tertiary-100: #1B283C;
	--color-black-100: #262626;
	--color-black-80: #515151;
	--color-black-50: #929292;
	--color-black-30: #BDBDBD;
	--color-black-10: #E9E9E9;
	--color-base:#FFFFFF;
	--color-short:#EA5B25;
	--color-regular:#FFE366;
	--color-long:#008FD2;
	--color-extra-wide:#1F976F;
	--color-dividers:#71B6E5;
	--color-brand-custom: #7864A6;
	--color-brand-1: #C5D5EC;
	--color-brand-easywrap: #62B3E3;
	--color-error: #721c24;
	--color-error-80: #EF7979;
	--color-nav-background: #EEEEEE;
	--color-sidebar: #37599f;


	// TYPOGRAPHY
	--font-one: 'Helvetica', sans-serif;
	--font-two: 'Helvetica Bold Extended', sans-serif;

	// CONTAINER SPACING
	--container-spacing: 1.25rem;
	@media (min-width: 576px) {
		--container-spacing: 2rem;
	}
	@media (min-width: 1200px) {
		--container-spacing: 5.3125rem;
	}

	--header-outer-height: 84px;
	--header-inner-height:70px;

	@media (min-width: $lg) {
		--header-outer-height: 124px;
	}

	--header-height-difference: calc(
		var(--header-outer-height) - var(--header-inner-height)
	);

	--component-gap: 2rem;

	@media (min-width: $md) {
		--component-gap: 5rem;
	}
}
