.offset-card {
    flex: 0 0 auto;
    align-self: flex-start;
    width: 275px;

    @media(min-width: $md) {
        width: 300px;
    }

    &__img {
        border-radius: .5rem;
        height: 275px;
        margin-bottom: 1rem;
        width: 275px;


        @media(min-width: $md) {
            height: 300px;
            width: 300px;
        }
    }

    &__heading {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: .75rem;
    }

    &--sm {
        width: 150px;

        .offset-card__img {
            height: 150px;
            width: 150px;
        }
    }
}