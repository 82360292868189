.image-text {
  margin-bottom: 2.5rem;

  @media(min-width: $md) {
    display: flex;
    margin-bottom: var(--component-gap);
  }

  .flipped {
    @media(min-width: $md) {
      order: 1;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--inline {
      border-radius: 8px;
      height: auto;
      object-fit: unset;
      max-width: 350px;
    }
  }

  &__subheading {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: .75rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 2.5rem 0;

    @media(min-width: $md) {
      padding: 0 1.25rem;
    }

    @media(min-width: $xl) {
      padding: 0 3.125rem;
    }
  }

  &__inner {
    max-width: 532px;
  }

  picture {
    height: 100%;
  }
}