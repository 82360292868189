.nav-container {
  background-color: var(--color-nav-background);
  border-radius: 8px;
  display: none;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, max(250px));
  grid-template-rows: repeat(2, 1fr);
  gap: 1.625rem;
  padding: 1.625rem;
  position: absolute;
  left: -500%;
  right: -500%;
  margin: auto;
  top: calc(100% + 1rem);
  max-width: calc(750px + 6.5rem);

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background-color: var(--color-nav-background);
  }

  &.open {
    display: grid;
  }

  &--list {
    flex-direction: column;
    max-width: 370px;

    &.open {
      display: flex;
    }
  }

  &--search {
    left: unset;
    right: -4rem;
    margin: auto;
    width: 305px;

    @media(min-width: $xs) {
      width: 360px;
    }

    @media(min-width: $md) {
      right: -1rem;
      width: 370px;
    }

    &:before {
      left: unset;
      right: 4.75rem;
      transform: rotate(45deg);

      @media(min-width: $md) {
        right: 1.75rem;
      }
    }

    &.open {
      display: flex;
    }
  }
}