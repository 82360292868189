@mixin themes {
  &--primary {
    border-color: var(--color-primary-100);
    background: var(--color-primary-100);
    color: var(--color-base);
  }

  &--secondary {
    border-color: var(--color-secondary-100);
    background: var(--color-secondary-100);
    color: var(--color-base);
  }

  &--tertiary {
    border-color: var(--color-tertiary-100);
    background: var(--color-tertiary-100);
    color: var(--color-base);
  }

  &--clear {
    border-color: var(--color-base);
    background: transparent;
    color: var(--color-base);
  }

  &--easywrap {
    border-color: var(--color-brand-easywrap);
    background: var(--color-brand-easywrap);
    color: var(--color-base);
  }

  &--error {
    border-color: var(--color-error-80);
    background: var(--color-error-80);
    color: var(--color-base);
  }

  &--short {
    border-color: var(--color-short);
    background: var(--color-short);
    color: var(--color-base);
  }

  &--regular {
    border-color: var(--color-regular);
    background: var(--color-regular);
    color: var(--color-black-100);
  }

  &--long {
    border-color: var(--color-long);
    background: var(--color-long);
    color: var(--color-base);
  }
}