.distributors {
  position: relative;

  &__heading {
    margin-bottom: 2rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 5rem;

    @media(min-width: $md) {
      margin-bottom: 7.5rem;
    }
  }
}