.nav-desktop {
  display: none;

  & > ul {
    display: flex;
    gap: 2rem;
    margin-right: 2rem;
  }

  @media(min-width: $lg) {
    display: block;
  }

  &__item:has(> .nav-desktop__sublink) {
    margin-bottom: .5rem;
  }

  &__link {
    color: var(--color-black-100);
    display: flex;
    font-weight: 300;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    gap: .5rem;
    width: 100%;

    &::after {
      content: url(/dist/img/icon-dropdown-arrow.svg);
      margin-bottom: 2px;
    }

    &--no-children {
      &::after {
        content: none;
        margin-bottom: 0;
      }
    }

    &.open {
      &::after {
        transform: scaleY(-1);
      }
    }
  }

  &__sublink {
    color: var(--color-tertiary-100);
    font-size: 1.0625rem;
    font-weight: 300;
    text-decoration: none;
  }

  &__container {
    background-color: var(--color-nav-background);
    border-radius: 8px;
    display: none;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, max(250px));
    grid-template-rows: repeat(2, 1fr);
    gap: 1.625rem;
    padding: 1.625rem;
    position: absolute;
    left: -500%;
    right: -500%;
    margin: auto;
    top: calc(100% + 1rem);
    max-width: calc(750px + 6.5rem);

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background-color: var(--color-nav-background);
    }

    &.open {
      display: grid;
    }

    &--list {
      &.open {
        display: flex;
        flex-direction: column;
        max-width: calc(370px);
      }
    }
  }

  &__section {
    padding: 1.5rem;
    background-color: var(--color-base);

    &--banner {
      background-color: var(--color-tertiary-100);
      color: var(--color-base);
    }
  }

  &__heading {
    color: var(--color-tertiary-100);
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: .75rem;
    padding-bottom: .5rem;
    position: relative;

    &::after {
      background-color: var(--color-nav-background);
      content: '';
      height: 1px;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
    }

    &--list {
      font-size: 1.0625rem;
      font-weight: 500;
      margin-bottom: 0;
      padding-bottom: 0;

      &::after {
        content: none;
      }
    }
  }

  &__description {
    margin-bottom: 1rem;

    p {
      color: var(--color-tertiary-100);
      font-size: 0.9375rem;
      font-weight: 300;
    }
  }
}