.socials {
    display: flex;
    gap: 2rem;
    justify-content: center;
    list-style: none;
    margin: 0 0 3rem;
    padding: 0;

    &__col {
        margin: 0;
    }
}