.header {
  &__outer {
    background-color: var(--color-base);
    box-shadow: 0 4px 8px 0 rgba(38, 38, 38, 0.20);
    display: flex;
    align-items: center;
    position: sticky;
    height: var(--header-outer-height);
    top: calc(
      var(--header-height-difference) * -1
    );
    z-index: 3;

    &.is-stuck .header__logo img {
      @media(min-width: $lg) {
        content: url(/dist/img/logo-text.svg);
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    height: var(--header-inner-height);
    max-width: 1500px;
    margin: 0 auto;
    padding: 15px 1.25rem;
    position: sticky;
    top: 3px;
    width: 100%;

    @media(min-width: $lg) {
      padding: 1rem 2rem;
    }
  }

  &__logo {
    margin-right: auto;

    img {
      width: 172px;

      @media(min-width: $sm) {
        width: 196px;
      }

      @media(min-width: $lg) {
        content: url(/dist/img/logo.svg);
      }
    }
  }

  &__buttons {
    display: flex;
    gap: .75rem;
  }

  &__button {
    cursor: pointer;

    &--menu {
      @media(min-width: $lg) {
       display: none;
      }

      &.open {
        content: url(/dist/img/btn-menu-open.svg);
      }
    }
  }
}
