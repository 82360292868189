.product-card {
  max-width: 300px;

  &__img {
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  &__heading {
    font-family: var(--font-one);
    font-size: 1.5rem;

    a {
      text-decoration: none;
    }
  }

  &__content {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }

  &__tags {
    margin-bottom: .75rem;
  }

  &__sku {
    color: var(--color-black-80);
    font-weight: 300;
    text-transform: uppercase;

  }

  &__link {
    align-self: flex-end;
    margin-bottom: 4px;
  }
}

