.hero {
    $self: &;

    @media(min-width: $md) {
        max-height: 600px;
        position: relative;
    }

    &__img {
        object-fit: cover;

        @media(min-width: $md) {
            object-fit: fill;
        }
    }

    &__content {
        padding: 1rem;
        text-align: center;

        @media(min-width: $md) {
            position: absolute;
            padding: 1.75rem;
            text-align: left;
            top: 20%;
            transform: none;
        }
    }

    &__heading, 
    &__subheading {
        h1, h2, h3, h4, p {
            font-family: var(--font-two);
            font-weight: 700;
            margin-bottom: 0;
            line-height: 1.2;
        } 
    }

    &__heading {
        margin-bottom: 1.75rem;

        h1, h2, h3, h4 {
            font-size: 2rem;

            @media(min-width: $md) {
                font-size: 2.25rem;
            }
        }
    }

    &__subheading {
        color: var(--color-black-100);
        margin-bottom: 1rem;
        max-width: 750px;

        @media(min-width: $md) {
            color: var(--color-base);
        }

        p {
            font-size: 1.25rem;
        }
    }

    &--simple {
        padding: 4.5rem 1rem 1.25rem;

        @media(min-width: $xs) {
            padding: 5.75rem 1.25rem 2.5rem;
        }

        @media(min-width: $md) {
            padding: 7rem 1.75rem 3.75rem;
        }

        @media(min-width: $lg) {
            padding: 7rem 5.3125rem 3.75rem;
        }

        #{$self}__heading {
            color: var(--color-black-100);
            margin-bottom: .5rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                border: solid 2px var(--color-secondary-100);
                border-radius: 5px;
                height: 0;
                width: 220px;
            }

            h1, h2, h3, h4 {
                @media(min-width: $md) {
                    font-size: 4.5rem;
                }
            }
        }

        #{$self}__subheading {
            color: var(--color-black-80);
            p {
                font-family: var(--font-one);
            }

            &--bold {
                p {
                    font-weight: 700;

                    @media(min-width: $md) {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .button--solid {
            background-color: var(--color-primary-100);
            border-color: var(--color-primary-100);
        }
    }

    &--full {
        border-radius: 8px;
        margin-bottom: 2rem;

        @media (min-width: $st) {
            display: grid;
            margin-bottom: 3.75rem;
        }

        & > * {
            @media (min-width: $st) {
                grid-area: 1/-1;
            }
        }

        picture {
            display: grid;
        }

        #{$self}__img,
        #{$self}__content {
            @media (min-width: $st) {
                grid-area: 1/-1;
            }
        }

        #{$self}__content {
            background: var(--color-base);
            border-radius: 8px;
            margin: 1.25rem;
            max-width: 588px;
            padding: 2rem 1.75rem;
            top: unset;
            left: unset;
            transform: none;
            text-align: left;

            @media (min-width: $st) {
                margin-left: 5.75rem;
                place-self: center start;
                position: relative;
            }

        }

        #{$self}__logo {
            margin-bottom: .75rem;

            @media (min-width: $st) {
                max-width: 80%;
            }
        }

        #{$self}__intro {
            margin-bottom: 1.5rem;

            p {
                font-size: 1rem;

                @media (min-width: $st) {
                    font-size: 1.25rem;
                }
            }

            a {
                color: var(--color-primary-100);
            }
        }
    }
}