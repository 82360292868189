.filter-pill-card {
    height: 195px;
    position: relative;
    min-width: 195px;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(27, 40, 60, 0.36) 0%, rgba(27, 40, 60, 0.36) 100%), var(--filter-pill-image) lightgray 50% / cover no-repeat;
    box-shadow: 4px 4px 48px 12px rgba(38, 38, 38, 0.10);
    

    &__heading {
        color: var(--color-base);
        font-size: 1.25rem;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        text-shadow: 0px 4px 8px rgba(38, 38, 38, 0.20);
        transform: translate(-50%, -50%);
        width: 100%;
    }

}