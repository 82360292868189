.swatch {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: .25rem;

  &__img {
    border-radius: 32px;
    height: 32px;
    width: 32px;
  }

  &__name {
    @include small-text-light;
  }
}