.schedule-appointment {
  background: var(--color-primary-100);
  color: var(--color-base);
  margin-bottom: 3.75rem;
  padding: 20px 0 40px;

  @media (min-width: $lg) {
    padding: 80px 0;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &__text {
    order: 0;

    h2 {
      margin-bottom: 1.375rem;
    }

    ul {
      @include list-minimal;
      padding-top:  1.375rem;
    }

    p, li {
      font-size: 1.375rem;
      font-weight: 700;
    }

    li {
      margin-bottom: 1.375rem;
      padding-left: 2rem;
      position: relative;

      &::before {
        content: url(/dist/img/icon-check.svg);
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
      }
    }

    .button {
      font-size: initial;
      font-weight: initial;
    }

    .list--ctas li {
      padding-left: 0;

      &::before {
        content: none;
      }

    }
  }

  &__img {
    order: 1;
  }

  h2 {
    color: var(--color-base);
  }

  .container {
    @media(min-width: $lg) {
      display: flex;
      align-items: center;
      gap: 133px;
    }
  }
}