.review-card {
    background-color: var(--color-black-10);
    color: var(--color-black-100);
    padding: 1.875rem 1.5rem;
    text-align: center;

    &__heading {
        margin-bottom: .75rem;
    }

    &__content {
        margin-bottom: 1.5rem;
    }

    &__name {
        color: var(--color-black-80);
        font-weight: 300;
        margin-bottom: .75rem;
        
    }

    &__stars {
        display: flex;
        justify-content: center;
        gap: .5rem;
    }
}