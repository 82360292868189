.distributor {
  $self: &;
  background-color: var(--color-black-10);
  border-radius: 8px;
  padding: 1.25rem;
  width: 100%;

  @media (min-width: 632px) {
    width: calc(50% - 1rem);
  }

  @media (min-width: $lg) {
    width: 300px;
  }

  &__name {
    align-items: center;
    color: var(--color-tertiary-100);
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .75rem;
  }

  &__flag {
    height: 40px;
    width: 40px;
  }

  &__meta {
    li {
      margin-bottom: .75rem;
    }
  }

  &__contact {
    color: var(--color-tertiary-100);
    font-weight: 700;
  }

  &__brands {
    padding: .75rem 0;
  }

  address {
    font-weight: 300;
    font-style: normal;
  }

  &--featured {
    background-color: var(--color-tertiary-100);

    #{$self}__name {
      color: var(--color-base);
    }

    #{$self}__contact  {
      color: var(--color-base);
    }

    address {
      color: var(--color-black-10);
      font-style: normal;
    }
  }
}