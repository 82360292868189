.codes-table {
  border: 0;
  border-collapse: collapse;
  border-radius: .5rem;
  background: var(--color-base);
  box-shadow: 4px 4px 48px 12px rgba(38, 38, 38, 0.10);
  overflow: hidden;

  &__row {
    td,
    th {
      border: 0;
      text-align: center;
    }

    th:first-of-type,
    td:nth-child(3n+1) {
      border-right: 2px solid var(--color-dividers);
    }

    td:last-of-type {
      border-right: 0;
    }

    &--heading,
    &--subheading {
      border-bottom: 2px solid var(--color-dividers);

      th:first-of-type {
        border-right: 0;
      }
    }

    &--heading {
      background: var(--color-primary-100);
      color: var(--color-base);
      font-size: 1.25rem;
    }

    &--subheading {
      background: var(--color-primary-20);
    }
  }
}