.info-box {
  border-radius: 8px;
  padding: .75rem;

  > p {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: .75rem;
  }

  @include themes;

  &--nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    p {
      font-size: .875rem;
      font-weight: 300;

      &:first-of-type {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: .75rem;
      }
    }

    .button {
      margin-top: auto;
      align-self: flex-end;
    }
  }
}