.measurement {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  @media(min-width: $md) {
    flex-direction: row;
    gap: 2.5rem;
  }

  &__img {
    border-radius: 8px;
  }

  &__meta {
    display: flex;
    gap: 1.5rem;
  }

  &__info {
    max-width: 300px;
  }

  &__label,
  &__heading {
    font-size: 1.25rem;
    font-weight: 700;
  }

  &__label {
    color: var(--color-secondary-100);
  }
}