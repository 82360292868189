.codes {
  &__radios:has(.codes__radio--clinic:checked) ~ .codes__panels .codes__panel--clinic,
  &__radios:has(.codes__radio--drug-tariff:checked) ~ .codes__panels .codes__panel--drug-tariff {
    display: block;
  }

  &__radios {
    display: flex;
    margin-bottom: 1.25rem;
  }

  &__radio {
    appearance: none;

    &--clinic:checked ~ .codes__label--clinic,
    &--drug-tariff:checked ~ .codes__label--drug-tariff {
      background: var(--color-secondary-100);
      color: var(--color-base);
    }
  }

  &__label {
    border-radius: 8px;
    background: var(--color-primary-20);
    color: var(--color-tertiary-100);
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 700;
    margin-right: 2rem;
    padding: 12px 20px;
  }

  &__panel {
    display: none;
    overflow-x: auto;
  }
}


