.offset-cards {
    $self: &;
    margin-bottom: 2.5rem;

    @media(min-width: $lg) {
        margin-bottom: 5rem;
    }

    &__container {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;
        margin-left: 1.25rem;
        overflow-x: auto;
        padding-right: 1.25rem;
        
        @media(min-width: $md) {
            gap: 2.75rem;
        }

        @media(min-width: $xxl) {
            justify-content: center;
        }
    }

    &__heading,
    &__intro {
        margin-bottom: 1rem;
    }

    &--articles {
        #{$self}__container {
            margin-left: 0;
            padding-right: 0;

            @media(min-width: $md) {
                gap: 2rem;
            }

            @media(min-width: $xxl) {
                justify-content: flex-start;
            }
        }
    }

    &--sm {
        #{$self}__container {
            gap: 1rem;
            justify-content: flex-start;
            margin-left: 0;
            margin-right: 0;
        }
    }
}