.form {
  background: var(--color-black-10);
  border-radius: 8px;
  padding: 2rem 1.75rem 2.5rem;

  &__heading {
    font-family: var(--font-two);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.75rem;
  }

  &__row {
    @media(min-width: $md) {
      display: flex;
      flex: 1 0;
      gap: 1.25rem;
    }
  }

  &__column {
    padding-bottom: 2rem;
    width: 100%;
  }

  &__errors {
    li {
      color: var(--color-error);
      margin-bottom: .75rem;

      &:first-of-type {
        padding-top: .75rem;
      }
    }
  }
}

.form-errors {
  padding: 15px;
  border: 1px solid #f5c6cb;
  background: #f8d7da;
  border-radius: 5px;
  color: #721c24;
  margin-bottom: 1rem;
}

.field {
  background-color: var(--color-base);
  display: block;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px;
  width: 100%;

  &__label {
    display: block;
    font-size: .75rem;
    font-weight: 300;
  }

  &__wrapper {
    display: flex;
    gap: .5rem;
  }

  &__input {
    appearance: none;
    background: transparent;
    border: 0;
    min-width: 180px;
    position: relative;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &:has(input.error) {
    border: solid 1px var(--color-error);
  }

  &:has(input:focus) {
    border: solid 1px var(--color-black-80);
  }
}