.articles-list {
  &__container {
    position: relative;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;


    &--has-more {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-right: 135px;

      @media(min-width: $md) {
        padding-right: 150px;
      }
    }
  }

  &__more {
    position: absolute;
    background: linear-gradient(270deg, #FFF 22.08%, rgba(255, 255, 255, 0.00) 100%);
    height: 100%;
    width: 134px;
    top: 0;
    right: 0;
  }

  &__button {
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }

    @media(min-width: $md) {
      right: -10px;
    }

    @media(min-width: $lg) {
      right: -20px;
    }
  }

  .article {
    flex: 1;
    min-width: 200px;
    width: 200px;

    @media(min-width: $md) {
      min-width: 280px;
    }

    @media(min-width: $lg) {
      min-width: 324px;
    }
  }
}