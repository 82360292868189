@mixin button-icon($image) {
	position: relative;
	padding-right: 2.25rem;

	&::after {
		content: url($image);
		height: 20px;
		width: 20px;
		right: .5rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.button {
	border-radius: 8px;
	border-style: solid;
	border-width: 2px;
	display: inline-block;
	padding: 8px 12px 6px;
	text-decoration: none;
	cursor: pointer;

	@include themes;

	&--full {
		width: 100%;
	}

	&--style-card {
		font-size: 0.75rem;
		font-weight: 700;
		width: 100%;
	}

	&--arrow {
		@include button-icon('/dist/img/icon-arrow-angled.svg');
	}

	&--calendar {
		@include button-icon('/dist/img/icon-calendar.svg');
	}

	&--download {
		@include button-icon('/dist/img/icon-download.svg');
	}

	&.freeform-processing {
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: center;

		&::before {
			content: "";
			display: block;
			flex: 1 0 11px;
			width: 11px;
			height: 11px;
			margin-right: 10px;
			border-style: solid;
			border-width: 2px;
			border-color: transparent transparent #fff #fff;
			border-radius: 50%;
			animation: freeform-processing .5s linear infinite;
		}
	}
}

@keyframes freeform-processing {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(1turn);
	}
}
