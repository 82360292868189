.listing {
    &--filtered {
        @media(min-width: $lg) {
            display: flex;
            gap: 3.25rem;
        }

        .results {
            margin-bottom: 2.5rem;
            flex-grow: 1;
        }
    }
}
