.product {
  &__sku {
    font-size: 1.25rem;
    margin-bottom: .25rem;
  }

  &__name {
    @media(min-width: $lg) {
      font-size: 2.5rem;
    }
  }

  &__intro {
    padding: 1rem 0;

    @media(min-width: $md) {
      padding: 1.25rem 0;
    }

    @media(min-width: $lg) {
      padding: 1.75rem 0;
    }

    p {
      font-weight: 300;
    }
  }

  &__button {
    margin-bottom: 1rem;

    @media(min-width: $md) {
      margin-bottom: 1.25rem;
    }

    @media(min-width: $lg) {
      margin-bottom: 1.75rem;
    }

    &--small-text {
      @media(min-width: $md) {
        display: none;
      }
    }

    &--large-text {
      display: none;

      @media(min-width: $md) {
        display: inline-block;
      }
    }
  }

  &__info {
    padding-top: 1.75rem;
    margin-bottom: 1rem;

    .info {
      margin-bottom: 1rem;
    }
  }

  &__info-title {
    font-weight: 700;
    margin-bottom: .5rem;
  }

  &__meta {
    margin-bottom: 2rem;

    @media(min-width: $lg) {
      flex-grow: 1;
      margin-bottom: 0;
      order: 1;
    }
  }

  &__carousel {
    width: 100%;
  }

  &__header {
    margin-bottom: 2.5rem;

    @media(min-width: $lg) {
      display: flex;
      gap: 2.5rem;
      margin-bottom: 3.75rem;
    }
  }
}