.breadcrumbs {
  display: flex;
  gap: .25rem;
  list-style: none;
  margin: 0;
  padding: 3.75rem 0 2.5rem;

  &__item {
    font-weight: 300;

    &::after {
      content: '>';
      padding-left: .25rem;
    }

    &:last-of-type::after {
      content: none;
    }
  }

  &__link {
    color: var(--color-black-80);
    font-weight: 700;
    text-decoration: none;
  }
}