.splide__arrows {
    position: absolute;
    top: 50%;
    width: 100%;
}

.splide__arrow {
    background-color: transparent !important;

    &:not(:disabled) {
        opacity: 1 !important;
    }

    &--prev {
        left: 0 !important;
    }

    &--next {
        right: 0 !important;
    }
}
