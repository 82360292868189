.list {
    &--minimal {
        @include list-minimal;
    } 

    &--ctas {
        display: flex;
        gap: .75rem;
        justify-content: center;
    }

    &--paging {
        align-items: center;
        display: flex;
        flex-shrink: 1;
        gap: 1.25rem;
        justify-content: center;
        margin: 0 auto;
    }

    &--flex-left {
        display: flex;
        flex-wrap: wrap;
        gap: .75rem;
        justify-content: flex-start;
    }

    &--hero,
    &--full {
        @media(min-width: $md) {
            justify-content: flex-start;
        }
    }

    &--left {
        justify-content: flex-start;
    }

    &--offset-cards {
        @media(max-width: 320px) {
            li {
                padding: 0 1.25rem;
                width: 100%;
            }
            
           .button {
                text-align: center;
                width: 100%;
           }
        }
    }

    &--full {
        @media(max-width: 480px) {
            li {
                padding: 0;
                width: 100%;
            }
            
           .button {
                text-align: center;
                width: 100%;
           }
        }
    }

    &--ticks {
        @include list-minimal;

        li {
            padding-left: 2rem;
            position: relative;
            &::before {
                content: url(/dist/img/icon-tick.svg);
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    &--brand {
        justify-content: flex-end;
    }

    &--icons {
        padding-top: 2rem;

        li {
            display: flex;
            align-items: center;
            font-weight: 300;
            margin: 0 0 1.25rem;
            gap: .75rem;

            &::before {
                display: inline-block;
                height: 24px;
                width: 24px;
            }

            &.envelope::before {
                content: url(/dist/img/icon-envelope-alt.svg);
            }

            &.phone::before {
                content: url(/dist/img/icon-phone-alt.svg);
            }

            &.pin::before {
                content: url(/dist/img/icon-pin-alt.svg);
            }

            &.icon-top::before {
                align-self: flex-start;
            }
        }
    }

    &--swatches {
        display: flex;
        gap: 1rem;
        justify-content: flex-start;

        @media (min-width: $sm) {
            gap: 1.75rem;
        }
    }
}
