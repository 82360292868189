@mixin rem($size) {
	$baseFontSize: $type__base-font-size / 1px;
	$fallbackSize: $size * 10px;
	$fontSize: ($size / $baseFontSize) * 1rem;

	font-size: $fallbackSize;
	font-size: $fontSize;
}

/* Mixin for font declaration syntax
Usage: @include font-face('Gotham', 'gotham');
*/
@mixin font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('/fonts/#{$font-filename}.woff2') format('woff2'),
			url('/fonts/#{$font-filename}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}

/* Mixin: Responsive font sizing
Usage:
// Sets every breakpoint to have a different font size
.foo {
	@include resp-font-size(22, 24, 28, 36, 48);
}

// Sets xs, sm and md to have different font sizes,
// lg and xl will then inherit the md size
.bar {
	@include resp-font-size(18, 20, 24)
}
*/
/* stylelint-disable */
@mixin resp-font-size($xs, $sm: $xs, $md: $sm, $lg: $md, $xl: $lg, $xxl: $xl) {
	@each $break, $size in ('xsmall': $xs, 'small': $sm, 'medium': $md, 'large': $lg, 'xlarge': $xl, 'xxlarge': $xxl) {
		@if unitless($size) {
			$size: $size * 1px;
		}
		@include breakpoint($break) {
			font-size: $size;
		}
	}
}
/* stylelint-enable */

@mixin small-text-light {
	font-size: .75rem;
	font-weight: 300;
}
