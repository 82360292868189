@font-face {
	font-family: "Helvetica Bold Extended";
	src: url('/dist/fonts/helvetica-bold-extended.woff2') format('woff2'), url('/dist/fonts/helvetica-bold-extended.woff') format('woff');
	font-display: swap;
	font-weight: 100;
}
@font-face {
	font-family: "Helvetica";
	src: url('/dist/fonts/helvetica-light.woff2') format('woff2'), url('/dist/fonts/helvetica-light.woff') format('woff');
	font-display: swap;
	font-weight: 300;
}
@font-face {
	font-family: "Helvetica";
	src: url('/dist/fonts/helvetica-regular.woff2') format('woff2'), url('/dist/fonts/helvetica-regular.woff') format('woff');
	font-display: swap;
	font-weight: 400;
}
@font-face {
	font-family: "Helvetica";
	src: url('/dist/fonts/helvetica-bold.woff2') format('woff2'), url('/dist/fonts/helvetica-bold.woff') format('woff');
	font-display: swap;
	font-weight: 700;
}
