.article {
    $self: &;

    display: flex;
    flex-direction: column;

    &__heading {
        font-size: 1rem;
        color: var(--color-primary-100);

        @media(min-width: $md) {
            margin-bottom: 1.25rem;
        }

        a {
            text-decoration: none;
        }
    }

    &__content {
        align-items: stretch; 
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    &__meta {
        width: 100%;

        @media(min-width: $md) {
            display: flex;
            align-items: center;
            gap: 1.25rem;
        }

        p {
            @include small-text-light;
        }
    }

    &__tags {
        padding: 1.25rem 0 1rem;

        @media(min-width: $md) {
            padding: 0;
        }
    }

    &__link {
        align-self: flex-end;
        flex-shrink: 0;
        margin-bottom: 4px;
    }

    &--alt {
        border-radius: 8px;
        padding: .75rem;

        #{$self}__heading {
            font-family: var(--font-one);
            font-size: 1rem;
        }
    }
}