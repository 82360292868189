.pill-card {
    display: flex;
    gap: 1rem;
    align-items: center;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__heading {
        font-family: var(--font-two);
        font-size: 1.5rem;
        margin-bottom: .5rem;
        line-height: 1;
    }

    &__description p {
        font-weight: 300;
    }

    &__img {
        height: 75px; 
        width: 75px;

        @media(min-width: $sm) {
            height: 90px; 
            width: 90px;
        }
    }
}