.logo-grid {
  &__intro {
    max-width: 720px;
  }

  &__grid {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2.5rem 0;

    @media(min-width: $lg) {
      gap: 43px;
    }
  }
}