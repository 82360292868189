.reviews {
    padding: 0 0 5rem;

    &__heading {
        text-align: center;
    }

    &__slider {
        max-width: 1330px;
        margin: 0 .5rem;

        @media(min-width: $sm) {
             margin: 0 1.75rem;
        }

        @media(min-width: $xl) {
            margin: 0 auto;
        }
       
    }

    &__track {
        margin: 0 1rem;

        @media(min-width: $sm) {
            margin: 0 4rem;
       }
    }
}