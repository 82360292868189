.footer {
    background-color: var(--color-black-10);
    padding: 3.125rem 0 2.5rem;

    &__container {
        display: flex;
        flex-direction: column;

        @media (min-width: $sm) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media (min-width: $lg) {
            display: grid;
            grid-template-columns: auto 1fr auto;
            gap: 120px;
        }
    }

    &__col {
        &--nav {
            @media (min-width: $sm) {
                width: 100%;
            }

            @media (max-width: $lg) {
                order: 1;
            }
        }
    }

    &__logo {
        margin-bottom: 1.25rem;
        width: 100%;
    }

    &__copyright {
        color: var(--color-black-80);
        font-weight: 300;
        text-align: center;
    }
}

.footer-nav {
    color: var(--color-black-80);

    @media (min-width: $md) {
        display: flex;
        gap: 2rem;
    }

    &__col {
        margin-bottom: 2.5rem;
    }

    &__heading {
        color: var(--color-black-100);
        font-family: var(--font-two);
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0 0 1.15rem;
        white-space: nowrap;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        margin: 0 0 0.75rem;

        &--icon { 
            margin: 0 0 .4rem;
        }

        &:last-of-type {
            margin: 0;
        }


    }

    &__link {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &--icon {
            display: inline-flex;
            align-items: center;
            gap: .75rem;
        }

        &--ml {
            align-items: flex-start;
        }

        &--passive {
            &:hover {
                text-decoration: none;
            }
        }

        span {
            display: inline-block;
            padding-top: 2px;
        }
    }
}
