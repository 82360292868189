.sidebar {
  display:none;
  position: sticky;
  top: calc(var(--header-inner-height) + 2rem);

  @media (min-width: $lg) {
    display: block;
  }

  &__nav {
    background-color: var(--color-black-10);
    border-radius: 8px;
    margin-bottom: 2rem;
    padding: 20px 12px;
  }

  &__heading {
    font-family: var(--font-one);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__item {
    margin-bottom: 1rem;
   
    &:hover {
      border-radius: 8px;
      background: var(--color-sidebar);
      cursor: pointer;
      font-weight: 700;

      a {
        color: var(--color-base);
      }
    }

    a {
      display: block;
      color: var(--color-black-100);
      text-decoration: none;
      padding: 8px 12px;
    }
  }
}