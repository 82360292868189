.filter {
    $self: &;
    align-self: flex-start;
    background-color: var(--color-black-10);
    border-radius: 8px;
    position: relative;
    padding: 1.25rem .75rem;
    margin-bottom: 2.5rem;
    min-width: 260px;

    &__drawer { 
        display: none; 
        padding-top: 1rem;

        @media(min-width: $md) {
            display: block;
        }
    }

    &__switch {
        appearance: none;
        position: absolute;
        top: 1.75rem;
        right: .75rem;
        height: 1rem;
        width: 1rem;

        @media(min-width: $md) {
            display: none;
        }

        &::after {
            position: absolute;
            top: 0;
            background: transparent url(/dist/img/icon-dropdown-arrow.svg) no-repeat center;
            background-size: cover;
            content: '';
            height: .625rem;
            width: 1rem; 
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__switch:checked {
        &::after {
            transform: rotate(180deg);
        }
    }

    &__switch:checked ~ &__drawer {
        display: block;
    }

    &__heading {
        align-items: center;
        color: var(--color-black-100);
        display: flex;
        font-weight: 700;
        font-size: 1.5rem;
        font-family: var(--font-one);
        justify-content: space-between;
    }
}