.container {
	width: 100%;
	max-width: 1500px;
	margin: auto;
	padding-left: var(--container-spacing);
	padding-right: var(--container-spacing);

	&--fullwidth {
		padding: 0;
	}

	&--footer {
		@media (min-width: $lg) {
			padding: 0;
		}
	}

	&--mb {
		margin-bottom: 1.25rem;
		@media(min-width: $lg) {
			margin-bottom: 3.75rem
		;
		}
	}
}

.columns {
  list-style: none;
  padding: 0;
  margin: 0 -1.25rem;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.columns {
	> * {
	width: calc(100% - 2.5rem);
	margin: 0 1.25rem 2.5rem;
	}

	&--3 {
		> * {
			width: calc(33.33% - 2.5rem);
		}
	}
}

.sidebar-container {
	position: relative;
	margin-bottom: 2rem;

	@media(min-width: $md) {
		margin-bottom: 3rem;
	}

	@media (min-width: $lg) {
		display: flex;
		gap: 3.25rem;
		margin-bottom: 7.5rem;
		padding-left: var(--container-spacing);
		padding-right: var(--container-spacing);
	}

	&__content {
		@media (min-width: $lg) {
			min-width: 0;
		}
	}

	&__sidebar {
		max-width: 260px;
		width: 100%;
	}

	.container {
		@media (min-width: $lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}

}

