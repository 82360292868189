.alternating-feature {
    $self: &;

    margin-bottom: 2.5rem;

    @media(min-width: $md) {
        display: flex;
        margin-bottom: 5rem;
    }

    .flipped {
        @media(min-width: $md) {
            order: 1;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--inline {
            border-radius: 8px;
            height: auto;
            object-fit: unset;
            max-width: 350px;
        }
    }

    &__heading {
        img {
            height: 75px;
            width: auto;
        }
    }

    &__subheading {
        font-size: 1.375rem;
        font-weight: 700;
        margin-bottom: .75rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        padding: 2.5rem 1rem;

        @media(min-width: $md) {
            padding: 2.5rem 1.25rem;
        }

        @media(min-width: $xl) {
            padding: 1rem 3.125rem;
        }

        &--dark {
            background-color: var(--color-tertiary-100);

            &, h4, h5, h6, p {
                color: var(--color-base);
            }
        }
    }

    &__inner {
        max-width: 532px;
    }

    picture {
        height: 100%;
    }

    &--list {
        margin-bottom: 2rem;

        @media(min-width: $md) {
            gap: 2rem;
        }

        @media(min-width: $lg) {
            margin-bottom: 5rem;
        }

        #{$self}__inner {
            max-width: 100%;
        }

        #{$self}__content {
            @media(min-width: $md) {
                padding: 0;
            }
        }

        h2 {
            margin-bottom: 1.25rem;
        }

        h3 {
            font-size: 1.25rem;
            margin-bottom: .5rem;
        }

        li {
            margin-bottom: 1.25rem;
        }
    }
}