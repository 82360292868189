.sizing-charts {
  margin-bottom: 4rem;

  &__row {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media(min-width: $md) {
        flex-direction: row;
        gap: 2rem;
      }
  }

  &__logo {
    padding-top: 1.25rem;
    width: 75px;

    @media(min-width: $md) {
      width: 180px;
    }
  }

  &__chart {
    display: flex;
    gap: 2rem;
    overflow-x: auto;
  }

  &__img {
    @media (max-width: $md) {
      height: 450px;
      width: auto;
      max-width: none;
      display: inline-block;
    }
  }

  .introduction {
    p {
      margin-bottom: 1rem;
    }
  }
}