.bold,
p strong {
  font-weight: 700;
}

.italic,
p em {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.underline-lg {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border: solid 2px var(--color-secondary-100);
    border-radius: 5px;
    height: 0;
    width: 220px;
  }
}

.upper {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.hide-sm {
  display: none;

  @media(min-width: $md) {
    display: flex;
  }
}

.image-preload {
  height: 0;
}

a.nostyle {
  color: inherit;
  text-decoration: none;
}

.has-icon {
  align-items: center;
  display: flex;
  gap: .75rem;

  &--top {
    align-items: flex-start;
  }
}

.introduction {
  margin-bottom: 1rem;
  max-width: 750px;
}

.bg {
  &--primary {
    background: var(--color-primary-100);
  }

  &--secondary {
    background: var(--color-secondary-100);
  }

  &--tertiary {
    background: var(--color-tertiary-100);
  }

  &--clear {
    background: transparent;
  }

  &--easywrap {
     background: var(--color-brand-easywrap);
  }

  &--black {
    background: var(--color-black-100);
  }

  &--black-10 {
    background: var(--color-black-10);
  }

  &--white {
    background: var(--color-base);
  }
}

.text {
  &--primary {
    color: var(--color-primary-100);
  }

  &--secondary {
    color: var(--color-secondary-100);
  }

  &--tertiary {
    color: var(--color-tertiary-100);
  }

  &--clear {
    color: transparent;
  }

  &--easywrap {
    color: var(--color-brand-easywrap);
  }

  &--black {
    color: var(--color-black-100);
  }

  &--black-10 {
    color: var(--color-black-10);
  }

  &--white {
    color: var(--color-base);
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.relative {
  position: relative;
}

.scrollto {
  scroll-margin: calc(var(--header-inner-height) + 1.75rem);
}

.separator {
  border: 0;
  height: 2px;
  background: var(--color-black-10);
}

.info {
  display: flex;
  align-items: center;
  gap: .5rem;

  &::before {
    content: url(/dist/img/icon-info.svg);
    height: 21px;
    width: 21px;
  }
}
