.pill {
    @include small-text-light;
    @include themes;

    border-radius: 8px;
    background: var(--color-black-10);
    color: var(--color-black-100);
    padding: 8px 8px 6px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;


    &--clear {
        border: solid 1px var(--color-black-10);
        background: transparent;
        color: var(--color-black-80);
    }

    &--chunky {
        font-weight: 700;
    }

    &--mb {
        margin-bottom: 0.5rem;
    }
}