.logo-card {
  background: var(--color-primary-20);
  border-radius: 8px;
  display: flex;
  flex-grow: 1;
  align-content: center;
  justify-content: center;
  height: 300px;
  padding: 2rem;

  @media(min-width: $md) {
    max-width: calc(50% - 1rem);
  }

  @media(min-width: $lg) {
    max-width: 300px;
  }

  &--carousel {
    background: transparent;
    max-width: 300px;
  }
}