.video {
  display: flex;
  margin-bottom: var(--component-gap);

  iframe {
    aspect-ratio: 16/9;
    border: 0;
    height: auto;
    max-width: var(--video-width, 100%);
  }

  &--left {
    justify-content: flex-start;
  }

  &--middle {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}