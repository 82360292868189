.contact-us {
  @media(min-width: $lg) {
    display: flex;
    gap: 2rem;
  }

  &__column {
    flex:1;

    &--form {
      @media(min-width: $lg) {
        order: 1;
      }
    }
  }

  &__address {
    font-style: normal;
  }
}