.logo-carousel {
  background-color: var(--color-tertiary-100);
  padding: 2.5rem 0 3.25rem;

  &__heading {
    color: var(--color-base);
    text-align: center;
  }

  &__slider {
    max-width: 1400px;
    margin: 0 .5rem;
    visibility: visible;

    @media(min-width: $sm) {
      margin: 0 1.75rem;
    }

    @media(min-width: $xl) {
      margin: 0 auto;
    }

  }

  &__track {
    margin: 0 1rem;

    @media(min-width: $sm) {
      margin: 0 2.25rem;
    }
  }
}
